import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ko';

export default function koreaTimeZone(date) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale('ko');
  dayjs.extend(relativeTime);
  dayjs.tz.setDefault('Asia/Seoul');

  // return dayjs().to(dayjs(date).tz().format('YYYY-MM-DD HH:mm:ss')); // xx 시간전
  return dayjs(date).add(9, 'hour').tz().format('YYYY-MM-DD HH:mm:ss'); // 현재 시각
}
