import { useEffect, useState } from 'react';
import {
  colorMappingData,
  ColorMappingPrimaryYAxis,
  pieChartData,
  rangeColorMapping,
} from '../../data/dummy';
import Applayout from '../Applayout';
import ColorMapping from '../Charts/ColorMapping';
import Pie from '../Charts/Pie';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { myProfileState } from '../../recoil/atom';
import {
  CHATTINGEL_UID,
  COMPOSE_EMAIL_UID,
  COVERLETTER_UID,
  DIALECT_CONVERSION_UID,
  DIARY_NEW_UID,
  DRAWINGEL_UID,
  EMAIL_SUMMARY_UID,
  FANTASY_WORLD_UID,
  FORMAL_DOCUMENTATION_UID,
  HOLIDAY_ANNOUNCEMENT_UID,
  LIFE_QUESTION_UID,
  MAKELYRICS_NEW_UID,
  MAKE_BLOG_CONTINUE_UID,
  MAKE_BLOG_IDEA_UID,
  MAKE_BLOG_INTRO_UID,
  MAKE_BLOG_OUTLINE_UID,
  MAKE_BUSINESS_ITEM_UID,
  MAKE_DISCUSSION_UID,
  MAKE_FAIRY_TAIL_INTRO_UID,
  MAKE_FAIRY_TAIL_STORY_CONTINUE_UID,
  MAKE_FIRST_SENTENCE_MARTIAL_UID,
  MAKE_MBTI_UID,
  MEETING_SUMMARY_UID,
  MONTHLY_REPORT_UID,
  NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID,
  NEWSLETTER_EDITOR_OUTLINE_UID,
  NEWSLETTR_EDITOR_TITLE_UID,
  NEXT_SENTENCE_UID,
  OBJECTIVE_SUBJECTIVE_UID,
  PRODUCT_INTRO_UID,
  PRODUCT_REVIEW_UID,
  PROPOSAL_CREATION_UID,
  RESUMEJOB_UID,
  SPELL_CHECKER_UID,
  STORYBOOK_CHAPTER_UID,
  STORYBOOK_STORY_UID,
  STORYBOOK_TITLE_UID,
  STORYBOOK_TOPIC_UID,
  STORY_SRC_UID,
  VOCABULAR_CORRECTION_UID,
  WEBNOVEL_PACKAGE1_UID,
  WEBNOVEL_PACKAGE2_UID,
  WORD_MASTER_UID,
  WRITING_SKILL_UID,
} from '../../data/uidConstant';
import { initialSubCategoryColorMappingData } from '../../data/initialData';

const UsageStatistics = () => {
  const [myProfile, setMyProfile] = useRecoilState(myProfileState);
  const [categoryMetrics, setCategoryMetrics] = useState([]);

  const [MajorCategoryPieData, setMajorCategoryPieData] = useState([]);

  const [subCategoryColorMappingData, setSubCategoryColorMappingData] =
    useState(initialSubCategoryColorMappingData); // 초기값 설정하기

  useEffect(() => {
    const getUsageMetrics = async () => {
      if (
        myProfile?.user?.email !== 'kostech111@gmail.com' &&
        !myProfile?.user?.group_uid
      )
        return;

      const uid =
        myProfile.user.email === 'kostech111@gmail.com'
          ? 'all'
          : myProfile.user.group_uid;
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/user/group/${uid}/metrics/category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      setCategoryMetrics(response.data.data.categoryMetrics);

      const initialMajorCategoryMetrics = [
        { x: '채팅젤', y: 0, text: 0 },
        { x: '드로잉젤', y: 0, text: 0 },
        { x: '동화만들기', y: 0, text: 0 },
        { x: '글쓰기', y: 0, text: 0 },
        { x: '마케팅', y: 0, text: 0 },
        { x: '비즈니스', y: 0, text: 0 },
        { x: '오피스', y: 0, text: 0 },
        { x: '글쓰기 보조도구', y: 0, text: 0 },
        { x: '외국어', y: 0, text: 0 },
      ];

      response.data.data.categoryMetrics.forEach((prompt) => {
        let index = 0;
        switch (prompt.prompt_uid) {
          case CHATTINGEL_UID:
            index = 0;
            break;
          case DRAWINGEL_UID:
            index = 1;
            break;
          case STORYBOOK_TOPIC_UID:
          case STORYBOOK_CHAPTER_UID:
          case STORYBOOK_STORY_UID:
          case STORYBOOK_TITLE_UID:
            index = 2;
            break;
          case MAKE_MBTI_UID:
          case MAKE_FIRST_SENTENCE_MARTIAL_UID:
          case LIFE_QUESTION_UID:
          case STORY_SRC_UID:
          case MAKELYRICS_NEW_UID:
          case FANTASY_WORLD_UID:
          case DIARY_NEW_UID:
          case WEBNOVEL_PACKAGE1_UID:
          case WEBNOVEL_PACKAGE2_UID:
          case MAKE_FAIRY_TAIL_INTRO_UID:
          case MAKE_FAIRY_TAIL_STORY_CONTINUE_UID:
          case NEXT_SENTENCE_UID:
          case COVERLETTER_UID:
            index = 3;
            break;
          case NEWSLETTER_EDITOR_OUTLINE_UID:
          case NEWSLETTR_EDITOR_TITLE_UID:
          case NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID:
          case MAKE_BLOG_IDEA_UID:
          case MAKE_BLOG_OUTLINE_UID:
          case MAKE_BLOG_INTRO_UID:
          case MAKE_BLOG_CONTINUE_UID:
          case PRODUCT_INTRO_UID:
          case PRODUCT_REVIEW_UID:
            index = 4;
            break;
          case MAKE_DISCUSSION_UID:
          case COMPOSE_EMAIL_UID:
          case EMAIL_SUMMARY_UID:
          case MAKE_BUSINESS_ITEM_UID:
          case RESUMEJOB_UID:
            index = 5;
            break;
          case HOLIDAY_ANNOUNCEMENT_UID:
          case MONTHLY_REPORT_UID:
          case FORMAL_DOCUMENTATION_UID:
          case PROPOSAL_CREATION_UID:
          case MEETING_SUMMARY_UID:
            index = 6;
            break;
          case WRITING_SKILL_UID:
          case SPELL_CHECKER_UID:
          case VOCABULAR_CORRECTION_UID:
          case DIALECT_CONVERSION_UID:
          case OBJECTIVE_SUBJECTIVE_UID:
            index = 7;
            break;
          case WORD_MASTER_UID:
            index = 8;
            break;
          default:
        }
        initialMajorCategoryMetrics[index].y += parseInt(prompt.count);
        initialMajorCategoryMetrics[index].text =
          parseInt(initialMajorCategoryMetrics[index].text) +
          parseInt(prompt.count);
      });

      const totalCount = initialMajorCategoryMetrics.reduce((acc, cur) => {
        return acc + cur.y;
      }, 0);

      const finalCategoryMetrics = initialMajorCategoryMetrics
        .filter(({ y }) => y !== 0)
        .map((metric) => {
          return {
            ...metric,
            text: ((metric.text / totalCount) * 100).toFixed(1) + '%',
          };
        });

      setMajorCategoryPieData(finalCategoryMetrics);

      // 컬러매핑(세부 컨텐츠별)
      const mappingData = subCategoryColorMappingData.map((data) => {
        const findedPromptData = response.data.data.categoryMetrics.find(
          (v) => v.prompt_uid === data.uid,
        );

        if (findedPromptData) {
          return {
            ...data,
            y: data.y + parseInt(findedPromptData.count),
          };
        }

        return data;
      });

      setSubCategoryColorMappingData(mappingData);
    };
    getUsageMetrics();
  }, [myProfile]);

  return (
    <Applayout>
      <Pie
        category={'category'}
        title={'카테고리별 사용 횟수'}
        // usageChartData={pieChartData}
        usageChartData={MajorCategoryPieData}
      />
      <ColorMapping
        id={'usageCharts1'}
        category={'contents'}
        title={'컨텐츠별 사용횟수'}
        dataSource={subCategoryColorMappingData.slice(0, 10)}
        seriesDirectiveName={'라이팅젤'}
        rangeColorMapping={rangeColorMapping}
        primaryYAxis={ColorMappingPrimaryYAxis}
      />
      <ColorMapping
        id={'usageCharts2'}
        category={'contents'}
        title={'컨텐츠별 사용횟수'}
        dataSource={subCategoryColorMappingData.slice(10, 20)}
        seriesDirectiveName={'라이팅젤'}
        rangeColorMapping={rangeColorMapping}
        primaryYAxis={ColorMappingPrimaryYAxis}
      />
      <ColorMapping
        id={'usageCharts3'}
        category={'contents'}
        title={'컨텐츠별 사용횟수'}
        dataSource={subCategoryColorMappingData.slice(20, 30)}
        seriesDirectiveName={'라이팅젤'}
        rangeColorMapping={rangeColorMapping}
        primaryYAxis={ColorMappingPrimaryYAxis}
      />
      <ColorMapping
        id={'usageCharts4'}
        category={'contents'}
        title={'컨텐츠별 사용횟수'}
        dataSource={subCategoryColorMappingData.slice(30, 40)}
        seriesDirectiveName={'라이팅젤'}
        rangeColorMapping={rangeColorMapping}
        primaryYAxis={ColorMappingPrimaryYAxis}
      />
      <ColorMapping
        id={'usageCharts5'}
        category={'contents'}
        title={'컨텐츠별 사용횟수'}
        dataSource={subCategoryColorMappingData.slice(40)}
        seriesDirectiveName={'라이팅젤'}
        rangeColorMapping={rangeColorMapping}
        primaryYAxis={ColorMappingPrimaryYAxis}
      />
    </Applayout>
  );
};

export default UsageStatistics;
