import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { myProfileState } from '../../recoil/atom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import koreaTimeZone from '../../hooks/koreaTimezone';
import Applayout from '../Applayout';
import { Header } from '../../components';
import { GridComponent, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  Inject,
} from '@syncfusion/ej2-react-charts';
import { usersGrid } from '../../data/dummy';
import { Pagination } from 'antd';

const GroupList = () => {
  const toolbarOptions = ['Search'];
  const [totalPage, setTotalPage] = useState(0);
  const editing = { allowDeleting: false, allowEditing: false };
  const gridRef = useRef(null);

  const [memberList, setMemberList] = useState([]);
  const [myProfile, setMyProfile] = useRecoilState(myProfileState);
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);
  const navigate = useNavigate();

  useEffect(() => {
    const getMembers = async () => {
      try {
        if (
          myProfile?.user?.email !== 'kostech111@gmail.com' &&
          !myProfile?.user?.group_uid
        ) {
          return;
        }

        const getMembersResponse = await axios.get(
          `${process.env.REACT_APP_BACK_URL}/user/group/list?page=${
            currentPage || 1
          }&limit=30`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        setTotalPage(getMembersResponse.data.config.maxPage || 1);

        let userList = [];

        if (myProfile?.user?.email !== 'kostech111@gmail.com') {
          // setMemberList(getMembersResponse.data.data.getUserList);
          userList = getMembersResponse.data.data.getUserList;
        } else {
          userList = getMembersResponse.data.data.userList;
        }

        const newMembers = userList?.map((member) => {
          // 구독 상품
          let firstSubscribedDate = '';
          if (
            member.membership_recent_date === null &&
            member.membership_start_date === null
          ) {
            firstSubscribedDate = '없음';
          }
          if (
            member.membership_start_date !== null &&
            member.membership_recent_date === null
          ) {
            firstSubscribedDate = member.membership_start_date;
          }
          if (
            member.membership_recent_date !== null &&
            member.membership_start_date !== null
          ) {
            firstSubscribedDate = member.membership_start_date;
          }

          let paymentDate = '';
          // 결제 일자
          if (
            member.membership_recent_date === null &&
            member.membership_start_date === null
          ) {
            paymentDate = '없음';
          }
          if (
            member.membership_recent_date !== null &&
            member.membership_start_date === null
          ) {
            paymentDate = member.membership_recent_date;
          }
          if (
            member.membership_recent_date !== null &&
            member.membership_start_date !== null
          ) {
            paymentDate = member.membership_recent_date;
          }

          return {
            nickname: member.name,
            email: member.email,
            recentlyLogin: koreaTimeZone(member.login_at),
            subscribedGift: `${member.membership_current}개월` || '없음',
            firstSubscribedDate:
              koreaTimeZone(firstSubscribedDate) === 'Invalid Date'
                ? '없음'
                : koreaTimeZone(firstSubscribedDate),
            paymentDate:
              paymentDate === '없음' ? '없음' : koreaTimeZone(paymentDate),
            user_image: member.picture,
          };
        });

        setMemberList(newMembers);
      } catch (err) {
        console.error(err);
      }
    };
    getMembers();
  }, [myProfile, currentPage]);

  return (
    <Applayout>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Pages" title="유저 목록" />
        <GridComponent
          ref={gridRef}
          dataSource={memberList}
          width="auto"
          allowSorting
          pageSettings={{
            pageCount: 5,
            pageSizes: true,
            enableQueryString: true,
          }}
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {usersGrid?.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Search, Toolbar]} />
        </GridComponent>
        <Pagination
          className="mt-[10px]"
          total={totalPage * 30}
          defaultCurrent={currentPage}
          pageSize={30}
          onChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            navigate(`/?page=${pageNumber}`);
          }}
        />
      </div>
    </Applayout>
  );
};

export default GroupList;
