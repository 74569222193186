import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React, { useEffect, useLayoutEffect } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useStateContext } from '../contexts/ContextProvider';
import { Navbar, Sidebar, ThemeSettings } from '../components';
import axios from 'axios';
import { SERVER_URL } from '../Config/server';
import { useRecoilState } from 'recoil';
import { myProfileState } from '../recoil/atom';
import { useNavigate } from 'react-router-dom';

const Applayout = ({ children }) => {
  const navigate = useNavigate();
  const [myProfile, setMyProfile] = useRecoilState(myProfileState);
  const { activeMenu, currentColor, themeSettings, setThemeSettings } =
    useStateContext();

  useEffect(() => {
    const loadMyProfile = async () => {
      try {
        const loadProfileResponse = await axios.get(
          `${SERVER_URL}/user/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        setMyProfile(loadProfileResponse.data.data);
        localStorage.setItem(
          'isAdmin',
          loadProfileResponse.data?.data?.user?.email ===
            'kostech111@gmail.com',
        );
      } catch (err) {
        console.error(err);
        navigate('/login');
      }
    };

    loadMyProfile();
  }, []);

  return (
    <div className="flex relative dark:bg-main-dark-bg">
      {
        <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
          <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{ background: currentColor, borderRadius: '50%' }}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div>
      }

      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white z-10">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg z-10">
          <Sidebar />
        </div>
      )}
      <div
        className={
          !activeMenu
            ? 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            : 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full'
        }
      >
        {
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar />
          </div>
        }

        <div>
          {themeSettings && <ThemeSettings />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Applayout;
