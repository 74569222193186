import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import {
//   Ecommerce,
//   Orders,
//   Calendar,
//   Employees,
//   Stacked,
//   Pyramid,
//   Customers,
//   Kanban,
//   Line,
//   Area,
//   Bar,
//   Pie,
//   Financial,
//   ColorPicker,
//   ColorMapping,
//   Editor,
// } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import GroupList from './pages/GroupList/GroupList';
import UsageStatistics from './pages/usage_statistics/UsageStatistics';
import UsageStatus from './pages/UsageStatus';
import UsageLog from './pages/usage_log/UsageLog';
import Login from './components/Login';
import Oauth from './components/Oauth';
import Kanban from './pages/Kanban';
import Editor from './pages/Editor';
import Scheduler from './pages/Calendar';
import ColorPicker from './pages/ColorPicker';
import PaymentStatistics from './pages/payment_statistics/PaymentStatistics';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    setActiveMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Routes>
          {/* dashboard  */}
          {/* <Route path="/" element={<Ecommerce />} /> */}
          {/* <Route path="/ecommerce" element={<Ecommerce />} /> */}

          {/* pages  */}
          {/* <Route path="/orders" element={<Orders />} />
                <Route path="/employees" element={<Employees />} />

                <Route path="/customers" element={<Customers />} /> */}

          {/* apps  */}
          {/* <Route path="/kanban" element={<Kanban />} />
          <Route path="/editor" element={<Editor />} />
          <Route path="/calendar" element={<Scheduler />} />
          <Route path="/color-picker" element={<ColorPicker />} /> */}

          {/* charts  */}
          {/* <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} /> */}

          {/* custom */}
          <Route path="/login" element={<Login />} />
          <Route path="/oauth" element={<Oauth />} />
          <Route path="/" element={<GroupList />} />
          <Route path="/usage_statistics" element={<UsageStatistics />} />
          <Route path="/usage_status" element={<UsageStatus />} />
          <Route path="/usage_log" element={<UsageLog />} />
          <Route path="/payment_statistics" element={<PaymentStatistics />} />
          <Route pathname="*" element={<>404</>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
