import { Box, Flex, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillFacebook } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import LoginHookForm from './LoginHookForm';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPopup,
} from 'firebase/auth';
import { SERVER_URL } from '../Config/server';
import axios from 'axios';
import { app } from '../Config/Firebase';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isInApp, SetInApp] = useState(false);
  const Toast = useToast();
  const navigate = useNavigate();
  const { Kakao } = window;

  const LoginKakao = () => {
    Kakao.Auth.authorize({
      redirectUri: `${process.env.FRONT_URL}/oauth`,
    });
  };

  const SignGoogle = () => {
    let provider = new GoogleAuthProvider();
    SignInGoogleFB(provider);
  };

  const SignFacebook = () => {
    let provider = new FacebookAuthProvider();
    SignInGoogleFB(provider);
  };

  const SignInGoogleFB = (provider) => {
    const auth = getAuth(app);
    auth.languageCode = 'ko';
    setLoading(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithPopup(auth, provider)
          .then(async (data) => {
            const user = data.user;
            const token = user.accessToken;

            await AndLogin(token);
          })
          .catch((error) => {
            console.log(error);
            const errorMessage = error.message;
            const popupBlocked = errorMessage.includes('auth/popup-blocked');
            if (popupBlocked) {
              Toast({
                position: 'top-right',
                title: '팝업이 차단되어 있습니다! ',
                description: '팝업 차단을 풀어주세요!',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
              setLoading(false);
            } else {
              Toast({
                position: 'top-right',
                title: 'Fail',
                description: errorMessage,
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
              setLoading(false);
            }
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        Toast({
          position: 'top-right',
          title: 'Fail',
          description: `[${errorCode}] ${errorMessage}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const AndLogin = async (token) => {
    const config = {
      method: 'post',
      url: `${SERVER_URL}/user/login`,
      data: {
        provider: 'firebase',
        token: token,
      },
    };
    await axios(config)
      .then((response) => {
        const LOGIN_TOKEN = response.data.data.token;
        localStorage.setItem('isLogin', true);
        localStorage.setItem('token', LOGIN_TOKEN);
        setLoading(false);
        navigate('/');
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        const errorResMessage = error.response.data.message;
        Toast({
          position: 'top-right',
          title: 'Fail',
          description: `[${errorStatus}] ${errorResMessage}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    let check = userAgent.indexOf('KAKAOTALK');
    let checkNaver = userAgent.indexOf('NAVER');
    let checkInsta = userAgent.indexOf('Instagram');
    let checkFB = userAgent.indexOf('FB');

    if (
      check !== -1 ||
      checkNaver !== -1 ||
      checkInsta !== -1 ||
      checkFB !== -1
    ) {
      SetInApp(true);
    } else {
      return;
    }
  }, [isInApp]);

  return (
    <div>
      <Box
        maxW="480px"
        m="0 auto"
        p="40px 20px"
        bg="#fff"
        marginTop={'80px'}
        border="1px solid #dedede"
        borderRadius={'10px'}
      >
        <Flex direction="column">
          <h3 className="text-[30px] font-[600] leading-[35px]">로그인</h3>
          <p className="text-[15px] mt-[10px] mb-[35px]">
            로그인 후 라이팅젤 대시보드 서비스를 이용하세요
          </p>
        </Flex>
        <Box>
          {/* 이메일로 로그인 */}
          <LoginHookForm setLoading={setLoading} />
        </Box>
        <Box marginTop={'30px'} textAlign="center" mb="10px">
          <Flex direction={'column'} align="center" justify={'center'}>
            {!isInApp && (
              <button
                className="border-[1px] border-solid border-[rgb(223,223,223)] flex items-center justify-center w-full gap-1.25 p-2.5 px-3.75 cursor-pointer rounded-lg mb-3.75 sign-btns google-btn bg-[#fff] text-[#444]"
                name="Google"
                onClick={SignGoogle}
              >
                <FcGoogle className="w-[28px] h-[28px]" /> 구글 로그인
              </button>
            )}
            <button
              id="kakao-login-btn"
              // className="shadow-[rgb(237 237 237) 2px 3px 8px] flex items-center justify-center w-full gap-[5px] p-[10px 15px] cursor-pointer border-0 outline-[0] rounded-[10px] text-[#fff] mb-[15px] hover:shadow-[rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset] sign-btns kakao-btn"
              className="mt-[10px] border-[1px] border-solid border-[rgb(223,223,223)] flex items-center justify-center w-full gap-1.25 p-2.5 px-3.75 cursor-pointer rounded-lg mb-3.75 bg-[#fee500] text-[rgb(0,0,0,0.85)]"
              name="kakao"
              onClick={LoginKakao}
            >
              <img
                className="w-[28px] h-[28px]"
                src="/kakao_symbol.png"
                alt="kakao"
              />
              <span>카카오 로그인</span>
            </button>
            <button
              className="mt-[10px] flex items-center justify-center w-full gap-1.25 p-2.5 px-3.75 cursor-pointer border-0 rounded-lg mb-3.75 sign-btns facebook-btn bg-[#3d5a97] text-[#fff]"
              name="Facebook"
              onClick={SignFacebook}
            >
              <AiFillFacebook className="w-[28px] h-[28px]" color="plain" />{' '}
              페이스북 로그인
            </button>
            {/* <div id="naverIdLogin"></div> */}
            {/* <div
                  id='naverIdLogin'
                 // ref={naverRef}
                  style={{ display: "none" }}
                ></div>{" "}
                <button
                  className='sign-btns naver-btn'
                  name='naver'
                  onClick={() => {
                    //console.log(naverRef.current.children)
                  //  naverRef.current.children[0].click();
                  }}
                >
                  <img src='/images/btnG_naver.png' alt='naver' />
                  <span>네이버 로그인</span>
                </button> */}
          </Flex>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
