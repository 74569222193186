import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
  extendTheme,
} from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';

const customTheme = extendTheme({
  breakpoints: {
    sm: '480px',
    md: '768px',
    lg: '960px',
    xl: '1024px',
    '2xl': '1200px',
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "'Spoqa Han Sans Neo', 'sans-serif'",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ChakraBaseProvider theme={customTheme}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </ChakraBaseProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
