import {
  CHATTINGEL_UID,
  COMPOSE_EMAIL_UID,
  COVERLETTER_UID,
  DIALECT_CONVERSION_UID,
  DIARY_NEW_UID,
  DRAWINGEL_UID,
  EMAIL_SUMMARY_UID,
  FANTASY_WORLD_UID,
  FORMAL_DOCUMENTATION_UID,
  HOLIDAY_ANNOUNCEMENT_UID,
  LIFE_QUESTION_UID,
  MAKELYRICS_NEW_UID,
  MAKE_BLOG_CONTINUE_UID,
  MAKE_BLOG_IDEA_UID,
  MAKE_BLOG_INTRO_UID,
  MAKE_BLOG_OUTLINE_UID,
  MAKE_BUSINESS_ITEM_UID,
  MAKE_DISCUSSION_UID,
  MAKE_FAIRY_TAIL_INTRO_UID,
  MAKE_FAIRY_TAIL_STORY_CONTINUE_UID,
  MAKE_FIRST_SENTENCE_MARTIAL_UID,
  MAKE_MBTI_UID,
  MEETING_SUMMARY_UID,
  MONTHLY_REPORT_UID,
  NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID,
  NEWSLETTER_EDITOR_OUTLINE_UID,
  NEWSLETTR_EDITOR_TITLE_UID,
  NEXT_SENTENCE_UID,
  OBJECTIVE_SUBJECTIVE_UID,
  PRODUCT_INTRO_UID,
  PRODUCT_REVIEW_UID,
  PROPOSAL_CREATION_UID,
  RESUMEJOB_UID,
  SPELL_CHECKER_UID,
  STORYBOOK_CHAPTER_UID,
  STORYBOOK_STORY_UID,
  STORYBOOK_TITLE_UID,
  STORYBOOK_TOPIC_UID,
  STORY_SRC_UID,
  VOCABULAR_CORRECTION_UID,
  WEBNOVEL_PACKAGE1_UID,
  WEBNOVEL_PACKAGE2_UID,
  WORD_MASTER_UID,
  WRITING_SKILL_UID,
} from './uidConstant';

export const initialSubCategoryColorMappingData = [
  { x: '채팅젤', y: 0, uid: CHATTINGEL_UID },
  { x: '드로잉젤', y: 0, uid: DRAWINGEL_UID },
  { x: '동화책 출간하기_주제 선정하기', y: 0, uid: STORYBOOK_TOPIC_UID },
  { x: '동화책 출간하기_챕터 만들기', y: 0, uid: STORYBOOK_CHAPTER_UID },
  { x: '동화책 출간하기_스토리 만들기', y: 0, uid: STORYBOOK_STORY_UID },
  { x: '동화책 출간하기_제목 만들기', y: 0, uid: STORYBOOK_TITLE_UID },
  { x: 'MBTI 연애편지', y: 0, uid: MAKE_MBTI_UID },
  { x: '첫문장 자판기', y: 0, uid: MAKE_FIRST_SENTENCE_MARTIAL_UID },
  { x: '글감찾기 질문 카드', y: 0, uid: LIFE_QUESTION_UID },
  { x: '이야기 재료 찾기', y: 0, uid: STORY_SRC_UID },
  { x: '판타지 세계관 생성', y: 0, uid: FANTASY_WORLD_UID },
  { x: '시(가사) 쓰기', y: 0, uid: MAKELYRICS_NEW_UID },
  { x: '일기 쓰기', y: 0, uid: DIARY_NEW_UID },
  { x: '소설 쓰기_도입부 쓰기', y: 0, uid: WEBNOVEL_PACKAGE1_UID },
  { x: '소설 쓰기_이어쓰기', y: 0, uid: WEBNOVEL_PACKAGE2_UID },
  { x: '동화 쓰기_도입부 쓰기', y: 0, uid: MAKE_FAIRY_TAIL_INTRO_UID },
  { x: '동화 쓰기_이어쓰기', y: 0, uid: MAKE_FAIRY_TAIL_STORY_CONTINUE_UID },
  { x: '뒷문장 자판기', y: 0, uid: NEXT_SENTENCE_UID },
  { x: '대입 자소서 자동 완성', y: 0, uid: COVERLETTER_UID },
  { x: '뉴스레터 에디터_개요 작성', y: 0, uid: NEWSLETTER_EDITOR_OUTLINE_UID },
  { x: '뉴스레터 에디터_제목 작성', y: 0, uid: NEWSLETTR_EDITOR_TITLE_UID },
  {
    x: '뉴스레터 에디터_리드글 작성',
    y: 0,
    uid: NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID,
  },
  { x: '블로그 글쓰기_아이디어 정하기', y: 0, uid: MAKE_BLOG_IDEA_UID },
  { x: '블로그 글쓰기_개요짜기', y: 0, uid: MAKE_BLOG_OUTLINE_UID },
  { x: '블로그 글쓰기_도입부 쓰기', y: 0, uid: MAKE_BLOG_INTRO_UID },
  { x: '블로그 글쓰기_이어쓰기', y: 0, uid: MAKE_BLOG_CONTINUE_UID },
  { x: '상품 소개문구 작성', y: 0, uid: PRODUCT_INTRO_UID },
  { x: '상품 후기 작성', y: 0, uid: PRODUCT_REVIEW_UID },
  { x: '찬반 논거', y: 0, uid: MAKE_DISCUSSION_UID },
  { x: '이메일 작성', y: 0, uid: COMPOSE_EMAIL_UID },
  { x: '이메일 요약', y: 0, uid: EMAIL_SUMMARY_UID },
  { x: '비즈니스 아이디어', y: 0, uid: MAKE_BUSINESS_ITEM_UID },
  { x: '취업 자소서 자동완성', y: 0, uid: RESUMEJOB_UID },
  { x: '월별 요약 보고', y: 0, uid: MONTHLY_REPORT_UID },
  { x: '회의록 요약', y: 0, uid: MEETING_SUMMARY_UID },
  { x: '휴무공지', y: 0, uid: HOLIDAY_ANNOUNCEMENT_UID },
  { x: '공식 문서 작성', y: 0, uid: FORMAL_DOCUMENTATION_UID },
  { x: '제안서 작성', y: 0, uid: PROPOSAL_CREATION_UID },
  { x: '글쓰기 능력 향상', y: 0, uid: WRITING_SKILL_UID },
  { x: '맞춤범 검사기', y: 0, uid: SPELL_CHECKER_UID },
  { x: '어휘력 교정기', y: 0, uid: VOCABULAR_CORRECTION_UID },
  { x: '사투리 변환기', y: 0, uid: DIALECT_CONVERSION_UID },
  { x: '객관적/주관적 관점 변환기', y: 0, uid: OBJECTIVE_SUBJECTIVE_UID },
  { x: '영단어 마스터', y: 0, uid: WORD_MASTER_UID },
];
