import React, { useEffect, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { contextMenuItems, logsData, logsGrid } from '../../data/dummy';
import { Header } from '../../components';
import Applayout from '../Applayout';
import axios from 'axios';
import { useRecoilSnapshot, useRecoilState } from 'recoil';
import { myProfileState } from '../../recoil/atom';
import { Pagination } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import koreaTimeZone from '../../hooks/koreaTimezone';

const UsageLog = () => {
  const editing = { allowDeleting: false, allowEditing: false };
  const [myProfile, setMyProfile] = useRecoilState(myProfileState);
  const [logs, setLogs] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1);

  useEffect(() => {
    const getLogs = async () => {
      try {
        if (
          myProfile?.user?.email !== 'kostech111@gmail.com' &&
          !myProfile?.user?.group_uid
        ) {
          return;
        }
        const uid =
          myProfile?.user?.email === 'kostech111@gmail.com'
            ? 'all'
            : myProfile?.user?.group_uid;
        const getLogsResponse = await axios.get(
          `${process.env.REACT_APP_BACK_URL}/user/group/${uid}/logs?page=${
            searchParams.get('page') || 1
          }&limit=30`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        const newLogs = getLogsResponse.data.data.promptLogList.map((log) => {
          return {
            nickname: log.user_name,
            email: log.user_email,
            content: log.name,
            input: log.input,
            output: log.output,
            output_image: log.output_image,
            date: koreaTimeZone(log.create_at),
            user_image: log.user_profile,
          };
        });

        setLogs(newLogs);
        setTotalPage(getLogsResponse.data.config.maxPage);
      } catch (err) {
        console.error(err);
      }
    };
    getLogs();
  }, [myProfile, currentPage, searchParams]);

  return (
    <Applayout>
      <div>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <Header category="Log" title="로그 기록" />
          <GridComponent
            id="gridcomp"
            dataSource={logs}
            allowSorting
            allowExcelExport
            allowPdfExport
            contextMenuItems={contextMenuItems}
            editSettings={editing}
            toolbar={['Search']}
          >
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {logsGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[
                Resize,
                Sort,
                ContextMenu,
                Filter,
                ExcelExport,
                Edit,
                PdfExport,
                Toolbar,
              ]}
            />
          </GridComponent>
          <Pagination
            className="mt-[10px]"
            defaultCurrent={currentPage}
            total={totalPage * 30}
            pageSize={30}
            onChange={(pageNumber) => {
              setCurrentPage(pageNumber);
              navigate(`/usage_log?page=${pageNumber}`);
            }}
          />
        </div>
      </div>
    </Applayout>
  );
};

export default UsageLog;
