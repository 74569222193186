import Applayout from '../Applayout';
import {
  ColorMappingPayPrimaryYAxis,
  rangeColorMapping,
} from '../../data/dummy';

import { Card, Select, Space } from 'antd';

import ColorMapping from '../Charts/ColorMapping';
import { initialSubCategoryColorMappingData } from '../../data/initialData';
import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

const PaymentStatistics = () => {
  const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

  const oneYearAgo = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  const sixMonthsAgo = dayjs().subtract(6, 'month').format('YYYY-MM-DD');
  const monthAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD');

  const [paymentRank, setPaymentRank] = useState({});
  const [paymentByPlan, setPaymentByPlan] = useState([]);
  const [planDate, setPlanDate] = useState(monthAgo);

  const tingelPlanCodes = {
    1: '라이팅젤 1개월 이용권',
    2: '라이팅젤 3개월 이용권',
    3: '라이팅젤 6개월 이용권',
    4: '통합 1개월 이용권',
    5: '통합 3개월 이용권',
    6: '통합 6개월 이용권',
    80: '드로잉젤',
    100000: '채팅젤',
  };

  const getPaymentByPlan = async (startDate, endDate) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACK_URL}/user/group/get-plan?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    return response.data.data;
  };

  useEffect(() => {
    const getRank = async (startDate, endDate) => {
      const response = await axios(
        `${process.env.REACT_APP_BACK_URL}/user/group/get-rank?start_date=${startDate}&end_date=${endDate}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      return response.data.data;
    };

    Promise.allSettled([
      getRank(oneYearAgo, currentDate),
      getRank(sixMonthsAgo, currentDate),
      getRank(monthAgo, currentDate),
    ]).then((res) => {
      const result = {
        oneYearBefore: res[0].value.filter((user) => user.total !== 0),
        sixMonthsBefore: res[1].value.filter((user) => user.total !== 0),
        oneMonthBefore: res[2].value.filter((user) => user.total !== 0),
      };
      setPaymentRank(result);
    });
  }, []);

  useEffect(() => {
    Promise.allSettled([getPaymentByPlan(planDate, currentDate)]).then(
      (res) => {
        // { x: '채팅젤', y: 0, uid: CHATTINGEL_UID },
        setPaymentByPlan(
          res[0]?.value
            ?.filter((el) => Object.keys(tingelPlanCodes).includes(el.plan))
            .map((el) => {
              return { x: tingelPlanCodes[el.plan], y: el.sum };
            }),
        );
      },
    );
  }, [planDate]);

  const { oneYearBefore, sixMonthsBefore, oneMonthBefore } = paymentRank;

  return (
    <Applayout>
      <section className="p-[10px] flex justify-around">
        <Card
          title="📈 올해의 결제 랭킹"
          bordered={false}
          // style={{ width: 300 }}
        >
          <div className="flex font-bold">
            <span className="w-[55px] block text-center">순위</span>
            <span className="w-[300px] block  text-center">이메일</span>
            <span className="w-[100px] text-center">총액</span>
          </div>
          {oneYearBefore?.map((user, index) => {
            return (
              <div className="flex">
                <span
                  className={`w-[55px] block text-center ${
                    index < 3 ? 'text-[#ff6961] font-bold' : ''
                  }`}
                >
                  {index + 1}
                </span>
                <span
                  className={`w-[300px] block  text-center ${
                    index < 3 ? 'text-[#ff6961] font-bold' : ''
                  }`}
                >
                  {/* 🥇강호중 님 */}
                  {user.user_email}&nbsp;님
                </span>
                <span
                  className={`w-[100px] text-center ${
                    index < 3 ? 'text-[#ff6961] font-bold' : ''
                  }`}
                >
                  {user.total.toLocaleString('ko-KR')}원
                </span>
              </div>
            );
          })}
        </Card>
        <Card title="📅 6개월 결제 랭킹" bordered={false}>
          <div className="flex font-bold">
            <span className="w-[55px] block text-center">순위</span>
            <span className="w-[300px] block  text-center">이메일</span>
            <span className="w-[100px] text-center">총액</span>
          </div>
          {sixMonthsBefore?.map((user, index) => {
            return (
              <div className="flex">
                <span
                  className={`w-[55px] block text-center ${
                    index < 3 ? 'text-[#6AC64C] font-bold' : ''
                  }`}
                >
                  {index + 1}
                </span>
                <span
                  className={`w-[300px] block  text-center ${
                    index < 3 ? 'text-[#6AC64C] font-bold' : ''
                  }`}
                >
                  {user.user_email}&nbsp;님
                </span>
                <span
                  className={`w-[100px] text-center ${
                    index < 3 ? 'text-[#6AC64C] font-bold' : ''
                  }`}
                >
                  {user.total.toLocaleString('ko-KR')}원
                </span>
              </div>
            );
          })}
        </Card>
        <Card title="📆 이달의 결제 랭킹" bordered={false}>
          <div className="flex font-bold">
            <span className="w-[55px] block text-center">순위</span>
            <span className="w-[300px] block  text-center">이메일</span>
            <span className="w-[100px] text-center">총액</span>
          </div>
          {oneMonthBefore?.map((user, index) => {
            return (
              <div className="flex">
                <span
                  className={`w-[55px] block text-center ${
                    index < 3 ? 'text-[#799fc4] font-bold' : ''
                  }`}
                >
                  {index + 1}
                </span>
                <span
                  className={`w-[300px] block  text-center ${
                    index < 3 ? 'text-[#799fc4] font-bold' : ''
                  }`}
                >
                  {user.user_email}&nbsp;님
                </span>
                <span
                  className={`w-[100px] text-center ${
                    index < 3 ? 'text-[#799fc4] font-bold' : ''
                  }`}
                >
                  {user.total.toLocaleString('ko-KR')}원
                </span>
              </div>
            );
          })}
        </Card>
      </section>

      <section className="relative">
        <div className="absolute right-[60px] top-[20px]">
          <Space wrap>
            <Select
              defaultValue="1"
              style={{ width: 120 }}
              onChange={async (value) => {
                // select value 에 맞는 데이터 불러오기
                // const oneYearAgo = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
                const startDate = dayjs()
                  .subtract(parseInt(value), 'month')
                  .format('YYYY-MM-DD');
                setPlanDate(startDate);
              }}
              options={[
                { value: '1', label: '1개월' },
                { value: '3', label: '3개월' },
                { value: '6', label: '6개월' },
                { value: '12', label: '1년' },
              ]}
            />
          </Space>
        </div>

        <ColorMapping
          id={'paymentByPlanCharts0'}
          category={'contents'}
          title={'상품 옵션별 결제총액'}
          dataSource={paymentByPlan.slice(0, 6)}
          seriesDirectiveName={'라이팅젤'}
          rangeColorMapping={rangeColorMapping}
          primaryYAxis={ColorMappingPayPrimaryYAxis}
        />
      </section>
      <section>
        <ColorMapping
          id={'paymentByPlanCharts1'}
          category={'contents'}
          title={'상품 옵션별 결제총액'}
          dataSource={paymentByPlan.slice(6)}
          seriesDirectiveName={'라이팅젤'}
          rangeColorMapping={rangeColorMapping}
          primaryYAxis={ColorMappingPayPrimaryYAxis}
        />
      </section>
    </Applayout>
  );
};

export default PaymentStatistics;
