// <채팅젤>
export const CHATTINGEL_UID = 'chatting-uid-100'; // prompt uid가 없어서 임의로 생성

// <드로잉젤>
export const DRAWINGEL_UID = 'drawing-uid-100'; // prompt uid가 없어서 임의로 생성

// <동화 만들기>
export const STORYBOOK_TOPIC_UID = 'd3f0abcc-5944-4cc4-a216-966335f4f222';
export const STORYBOOK_CHAPTER_UID = '8ce6cc2e-a2d4-4389-b659-2b48dc1bb3e9';
export const STORYBOOK_STORY_UID = '3fd82f0f-c724-4f44-b040-dbaade98df11';
export const STORYBOOK_TITLE_UID = '678a4cc9-f370-4440-a896-f927fd024eb9';

// <글쓰기>
export const MAKE_MBTI_UID = '40902a0f-7b0f-4dfa-bfa3-2fc7c6a2915e';
export const MAKE_FIRST_SENTENCE_MARTIAL_UID = 'make-sentence-100'; // 하나의 컨텐츠에 prompt가 여러개라 통합용으로 임의로 하나 생성
export const LIFE_QUESTION_UID = '156406a8-0631-4d14-922e-6867aa719208';
export const STORY_SRC_UID = 'a0f933c1-35df-4da3-9478-41b56719c9e3';

export const MAKELYRICS_NEW_UID = 'c658c5ec-8efb-4198-a997-c0b61ed197f1';
export const FANTASY_WORLD_UID = 'fantasy-world-100'; // 하나의 컨텐츠에 prompt가 여러개라 통합용으로 임의로 하나 생성
export const DIARY_NEW_UID = '59f866d7-8b9e-439d-a51f-11fc7a0a384f';
export const WEBNOVEL_PACKAGE1_UID = '85b6599c-9528-45aa-84a1-026fed2c46f2'; // 소설 도입부 쓰기
export const WEBNOVEL_PACKAGE2_UID = 'bf768f1d-9699-411c-8557-4d1ff7338232'; // 소설 이어쓰기
export const MAKE_FAIRY_TAIL_INTRO_UID = '717460ab-d777-4a7d-bac7-5de18c0e76ae';
export const MAKE_FAIRY_TAIL_STORY_CONTINUE_UID =
  'make-fairy-tail-story-continue-100';
export const NEXT_SENTENCE_UID = 'd517029d-215e-4d21-aa25-1d4185fde846';
export const COVERLETTER_UID = 'coverletter-100'; // 하나의 컨텐츠에 prompt가 여러개라 통합용으로 임의로 하나 생성

// <마케팅>
export const NEWSLETTER_EDITOR_OUTLINE_UID =
  'aea81dcb-3950-4806-a7d9-66eb34ca25e3';
export const NEWSLETTR_EDITOR_TITLE_UID =
  'bc7e4321-1f57-4565-a4fd-3fab9c450f2e';
export const NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID =
  '4e75c593-ec0d-4c4b-855a-e4192aa3b588';

export const MAKE_BLOG_IDEA_UID = '1c9c4617-8591-40ef-9312-63c5b3ae5991';
export const MAKE_BLOG_OUTLINE_UID = '71ec198e-f4d8-4298-ac46-34b31fe082cc';
export const MAKE_BLOG_INTRO_UID = 'aced8fda-6bc6-4d65-8ecd-5610c40ef169';
export const MAKE_BLOG_CONTINUE_UID = '3937aa68-2d5f-4e22-baef-b984915d37cc';

export const PRODUCT_INTRO_UID = 'c3ea083d-737a-482c-b69e-3aed893c9442';
export const PRODUCT_REVIEW_UID = '0eac3353-6607-4e5d-a75b-150d601ad8e7';

// <비즈니스>
export const MAKE_DISCUSSION_UID = '53b23b61-d433-4c2d-a381-6109a6cbfe01';
export const COMPOSE_EMAIL_UID = '597fe25f-acb3-4b85-80b9-0d190dd637b1';
export const EMAIL_SUMMARY_UID = '2dc996d3-f407-497c-99f6-80de36a680ce';

export const MAKE_BUSINESS_ITEM_UID = '4de86cbd-24bd-4e56-a644-34945a50fc23';
export const RESUMEJOB_UID = '063ecb30-1ee6-464b-9870-ab85a03503b6';

// <오피스>
export const HOLIDAY_ANNOUNCEMENT_UID = '233253f1-2e01-4f1a-a538-dd4b5e491385';
export const MONTHLY_REPORT_UID = 'a01e255e-ecbd-4aaf-a779-c4168ee693e9';
export const FORMAL_DOCUMENTATION_UID = '46e21020-00ec-44a9-882e-d3535f0e2723';
export const PROPOSAL_CREATION_UID = '5974463e-d88f-4a6c-a052-070e69638ac1';
export const MEETING_SUMMARY_UID = '1149920b-867f-4973-974c-381c1512c181';

// <글쓰기 보조도구>
export const WRITING_SKILL_UID = 'b7e100f4-e128-498a-aad6-9d0558f0f9e9';
export const SPELL_CHECKER_UID = 'ad1d7b59-0560-4b42-a180-d5236741fb85';
export const VOCABULAR_CORRECTION_UID = 'e942f432-5af6-47f3-95db-5ef9ca9af424';
export const DIALECT_CONVERSION_UID = 'e563c93d-fd6b-484f-9a38-c93d536a54f5';
export const OBJECTIVE_SUBJECTIVE_UID = 'c63767c3-5da5-4426-8d56-e55c2d0617e4';

// <외국어>
export const WORD_MASTER_UID = '64c7afdd-b533-493c-81d1-cb352af2d18f';
