import React, { useEffect, useState } from 'react';
import { Header } from '../components';
import { userStatusGrid } from '../data/dummy';
import {
  Inject,
  ColumnsDirective,
  Search,
  Page,
  GridComponent,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { ColumnDirective } from '@syncfusion/ej2-react-charts';
import Applayout from './Applayout';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { myProfileState } from '../recoil/atom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination } from 'antd';
import {
  CHATTINGEL_UID,
  COMPOSE_EMAIL_UID,
  COVERLETTER_UID,
  DIALECT_CONVERSION_UID,
  DIARY_NEW_UID,
  DRAWINGEL_UID,
  EMAIL_SUMMARY_UID,
  FANTASY_WORLD_UID,
  FORMAL_DOCUMENTATION_UID,
  HOLIDAY_ANNOUNCEMENT_UID,
  LIFE_QUESTION_UID,
  MAKELYRICS_NEW_UID,
  MAKE_BLOG_CONTINUE_UID,
  MAKE_BLOG_IDEA_UID,
  MAKE_BLOG_INTRO_UID,
  MAKE_BLOG_OUTLINE_UID,
  MAKE_BUSINESS_ITEM_UID,
  MAKE_DISCUSSION_UID,
  MAKE_FAIRY_TAIL_INTRO_UID,
  MAKE_FAIRY_TAIL_STORY_CONTINUE_UID,
  MAKE_FIRST_SENTENCE_MARTIAL_UID,
  MAKE_MBTI_UID,
  MEETING_SUMMARY_UID,
  MONTHLY_REPORT_UID,
  NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID,
  NEWSLETTER_EDITOR_OUTLINE_UID,
  NEWSLETTR_EDITOR_TITLE_UID,
  NEXT_SENTENCE_UID,
  OBJECTIVE_SUBJECTIVE_UID,
  PRODUCT_INTRO_UID,
  PRODUCT_REVIEW_UID,
  PROPOSAL_CREATION_UID,
  RESUMEJOB_UID,
  SPELL_CHECKER_UID,
  STORYBOOK_CHAPTER_UID,
  STORYBOOK_STORY_UID,
  STORYBOOK_TITLE_UID,
  STORYBOOK_TOPIC_UID,
  STORY_SRC_UID,
  VOCABULAR_CORRECTION_UID,
  WEBNOVEL_PACKAGE1_UID,
  WEBNOVEL_PACKAGE2_UID,
  WRITING_SKILL_UID,
} from '../data/uidConstant';

const UsageStatus = () => {
  const toolbarOptions = ['Search'];
  const [myProfile, setMyProfile] = useRecoilState(myProfileState);
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page')) || 1,
  );
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const [usageMetricsByUser, setUsageMetricsByUser] = useState([]);

  // user/group/:group_uid/metrics/user
  useEffect(() => {
    const getMetricsByUser = async () => {
      try {
        if (
          myProfile?.user?.email !== 'kostech111@gmail.com' &&
          !myProfile?.user?.group_uid
        ) {
          return;
        }
        const uid =
          myProfile?.user?.email === 'kostech111@gmail.com'
            ? 'all'
            : myProfile.user.group_uid;
        const metricByUserResponse = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/user/group/${uid}/metrics/user`,
          {
            page: currentPage,
            count: 30,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        setTotalPage(metricByUserResponse.data.config.maxPage);

        const findPromptUsageCount = (userPromptLog, uid) =>
          parseInt(userPromptLog.find((v) => v.prompt_uid === uid)?.count) || 0;

        const newUserUsageMetrics =
          metricByUserResponse.data.data.userMetrics.map(
            ({ user, userPromptLog }) => {
              return {
                nickname: user.name,
                email: user.email,
                chattingel: findPromptUsageCount(userPromptLog, CHATTINGEL_UID),
                drawingel: findPromptUsageCount(userPromptLog, DRAWINGEL_UID),
                childrenLiterature:
                  findPromptUsageCount(userPromptLog, STORYBOOK_CHAPTER_UID) +
                  findPromptUsageCount(userPromptLog, STORYBOOK_STORY_UID) +
                  findPromptUsageCount(userPromptLog, STORYBOOK_TITLE_UID) +
                  findPromptUsageCount(userPromptLog, STORYBOOK_TOPIC_UID),
                writing:
                  findPromptUsageCount(userPromptLog, MAKE_MBTI_UID) +
                  findPromptUsageCount(
                    userPromptLog,
                    MAKE_FIRST_SENTENCE_MARTIAL_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, LIFE_QUESTION_UID) +
                  findPromptUsageCount(userPromptLog, STORY_SRC_UID) +
                  findPromptUsageCount(userPromptLog, MAKELYRICS_NEW_UID) +
                  findPromptUsageCount(userPromptLog, FANTASY_WORLD_UID) +
                  findPromptUsageCount(userPromptLog, DIARY_NEW_UID) +
                  findPromptUsageCount(userPromptLog, WEBNOVEL_PACKAGE1_UID) +
                  findPromptUsageCount(userPromptLog, WEBNOVEL_PACKAGE2_UID) +
                  findPromptUsageCount(
                    userPromptLog,
                    MAKE_FAIRY_TAIL_INTRO_UID,
                  ) +
                  findPromptUsageCount(
                    userPromptLog,
                    MAKE_FAIRY_TAIL_STORY_CONTINUE_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, NEXT_SENTENCE_UID) +
                  findPromptUsageCount(userPromptLog, COVERLETTER_UID),
                marketing:
                  findPromptUsageCount(
                    userPromptLog,
                    NEWSLETTER_EDITOR_OUTLINE_UID,
                  ) +
                  findPromptUsageCount(
                    userPromptLog,
                    NEWSLETTR_EDITOR_TITLE_UID,
                  ) +
                  findPromptUsageCount(
                    userPromptLog,
                    NEWSLETTER_EDITOR_LEAD_PARAGRAPH_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, MAKE_BLOG_IDEA_UID) +
                  findPromptUsageCount(userPromptLog, MAKE_BLOG_OUTLINE_UID) +
                  findPromptUsageCount(userPromptLog, MAKE_BLOG_INTRO_UID) +
                  findPromptUsageCount(userPromptLog, MAKE_BLOG_CONTINUE_UID) +
                  findPromptUsageCount(userPromptLog, PRODUCT_INTRO_UID) +
                  findPromptUsageCount(userPromptLog, PRODUCT_REVIEW_UID),

                business:
                  findPromptUsageCount(userPromptLog, MAKE_DISCUSSION_UID) +
                  findPromptUsageCount(userPromptLog, COMPOSE_EMAIL_UID) +
                  findPromptUsageCount(userPromptLog, EMAIL_SUMMARY_UID) +
                  findPromptUsageCount(userPromptLog, MAKE_BUSINESS_ITEM_UID) +
                  findPromptUsageCount(userPromptLog, RESUMEJOB_UID),
                office:
                  findPromptUsageCount(
                    userPromptLog,
                    HOLIDAY_ANNOUNCEMENT_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, MONTHLY_REPORT_UID) +
                  findPromptUsageCount(
                    userPromptLog,
                    FORMAL_DOCUMENTATION_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, PROPOSAL_CREATION_UID) +
                  findPromptUsageCount(userPromptLog, MEETING_SUMMARY_UID),
                subTools:
                  findPromptUsageCount(userPromptLog, WRITING_SKILL_UID) +
                  findPromptUsageCount(userPromptLog, SPELL_CHECKER_UID) +
                  findPromptUsageCount(
                    userPromptLog,
                    VOCABULAR_CORRECTION_UID,
                  ) +
                  findPromptUsageCount(userPromptLog, DIALECT_CONVERSION_UID) +
                  findPromptUsageCount(userPromptLog, OBJECTIVE_SUBJECTIVE_UID),
                user_image: user.picture,
              };
            },
          );

        setUsageMetricsByUser(newUserUsageMetrics);
      } catch (err) {
        console.error(err);
      }
    };
    getMetricsByUser();
  }, [myProfile, currentPage]);

  const editing = { allowDeleting: true, allowEditing: true };
  return (
    <Applayout>
      <div>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <Header category="Pages" title="사용 현황" />
          <GridComponent
            // dataSource={usersStatusData}
            dataSource={usageMetricsByUser}
            width="auto"
            allowSorting
            editSettings={editing}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {userStatusGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Toolbar]} />
          </GridComponent>
          <Pagination
            className="mt-[10px]"
            defaultCurrent={currentPage}
            total={totalPage * 30}
            pageSize={30}
            onChange={(pageNumber) => {
              setCurrentPage(pageNumber);
              navigate(`/usage_status?page=${pageNumber}`);
            }}
          />
        </div>
      </div>
    </Applayout>
  );
};

export default UsageStatus;
